







































































import Vue, { VueConstructor } from 'vue'
import BalanceCard from '@/components/BalanceCard.vue'
import Organization from '../../../../../store/models/Organization'
import AccountMixin from '../../../../../mixins/accountMixin'

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof AccountMixin>
>).extend({
  components: {
    BalanceCard
  },

  mixins: [AccountMixin],

  computed: {
    organization() {
      return Organization.loggedIn()
    },
    moovBalance(this: any): number {
      return this.soldeDecaissementMoov
    },
    mtnBalance(this: any): number {
      return this.soldeDecaissementMtn
    },
    totalBalance(this: any): number {
      return this.moovBalance + this.mtnBalance
    }
  },

  methods: {
    onSeeDetails() {
      // this.$router.push({ name: 'refunds.group' })
    }
  }
})
